/* Show it is fixed to the top */

@import url("hover.css");
@import url("https://kit.fontawesome.com/10c6d06971.js");

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
  line-height: 2;
  font-size: 14px;
}

section
{
	position: relative;
	left: 0%;
	height: 2vh;
}

.bg-light
{
	background-color: #fafafa !important;
}

.bg-blue
{
	background-color: #1d72a8 !important;
	color:#fff;
}

#toplink
{
	display: none; 
	position: fixed; 
	bottom: 15px; 
	right: 30px; 
	color: #1E7FC9; 
	font-size: 25px;
	padding: 8px; 
	padding-top: 2px; 
	border-radius: 5px; 
	cursor: pointer; 
	z-index: 10000;
}


#scrollbtn
{
	position: fixed;
	width: 114px;
	margin-left: -57px;
	bottom: 15px;
	left: 50%;
}


#topcloser
{
	background-color: #338bbf;
	width: 100vw;
	position: fixed;
	height: 0vh;
	z-index: 10000;
	left: 0px;
	top: -100px;
}

#bottomcloser
{
	background-color: #338bbf;
	width: 100vw;
	position: fixed;
	height: 0vh;
	z-index: 10000;
	bottom: -100px;
	left: 0px;	
}

.topicon
{
	position: absolute;
	bottom: 0px;
	left: 50%;
	margin-left: -110px;
}

.bottomicon
{
	position: absolute;
	top: 0px;
	left: 50%;
	margin-left: -110px;
}


.wrapper
{
	height: 100vh;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:hover
{
	border:0px;
	cursor: pointer;
	outline: none;
	position: relative;
	z-index: 100;
}

.navbar-toggler span i
{
	color: #1E7FC9;
	font-size: 1.2em;
}

.navbar-light
{
	background: transparent !important;
	opacity: 1;
}


.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.sideNav_active {
	width: 250px !important;
}


/* The navigation menu links */
.sidenav a {
  padding: 0px 8px 0px 32px;
  text-decoration: none;
  font-size: 22px;
  line-height: 1.8;
  color: #d1d1d1;
  display: block;
  transition: 0.3s;
}

.sidenav span {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #1E7FC9;
  display: block;
  transition: 0.3s;
  margin-top: 30px;
}

.sidenav .inline
{
	display: inline;
 	padding: 0px;
}

.sidenav .pl32
{
 	padding-left: 32px !important;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Standaard styles */

a
{
	color: #1E7FC9;
}

a:hover
{
	text-decoration: none;
	color: inherit;
}

a.text-muted:hover
{
	text-decoration: none;
	color: #1E7FC9 !important;
}

.content
{
	opacity: 0;
}

.portfolio-nav a
{
	color:#c2c2c2;
	font-size:0.7em;
}

.portfolio-sidenav
{
	color:#c2c2c2;
	font-size:0.7em;
}

.portfolio-sidenav a
{
	color:#c2c2c2;
}

.relative
{
	position: relative;
}

.blue_l
{
	color: #90CDED;
}

.blue
{
	color: #1d72a8;
}


.icon_gb
{
	background-image: url('/include/images/Guido-Backerra-icon.png');
	background-size: contain;
	background-repeat: no-repeat;
	height: 25px;
	width: 25px;
	display: inline-block;
	background-repeat: no-repeat;
	margin-bottom: -10px;
	margin-right: 20px;
}


h1,
h2.lg
{
	font-weight: normal;
	text-transform: uppercase;
	font-size: 40px;
	margin-bottom:10px;
	letter-spacing: 2px;
}


h2
{
	font-weight: normal;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom:30px;
	letter-spacing: 2px;
}

h2.bold
{
	font-weight: 300;
	text-transform: uppercase;
	font-size: 22px;	
}

h2.banner
{
	font-size: 32px;
}

h2.banner small
{
	font-size: 14px;
}


h3
{
	color: #000;
	font-size: 32px;
	font-weight: 400;
	margin-top: 30px;
	margin-bottom: 0px;
	text-transform: uppercase;
}

h3.normal
{
	text-transform: uppercase;
	font-size: 32px;
	margin-bottom:60px;
	margin-top: 0px;
}

h3.large
{
	text-transform: uppercase;
	font-size: 41px;
	margin-top: 0px;
	font-weight: 300;
    font-family: 'Nunito', sans-serif;
}

p
{
	margin-bottom:30px;
}

.mybtn
{
	background-color: #E0E1E2;
	padding: 10px 0px 10px 10px;
	font-weight: 500;
	font-size: 14px;
	// border-radius: 5px;
	color: #464646;

}
.mybtn > span
{
 	padding: 10px 0px 10px 8px;
}

.mybtn .icon {
  	font-size: 11px;
  	background-color: #D7D7D8;
 	padding: 14px 12px 12px 12px;
	// border-radius: 0px 5px 5px 0px;
	margin-left: 5px;
}

.mybtn:hover
{
	color: #0C73AA !important ;
	text-decoration: none;
}


.mybtn-blue
{
	background-color: #5FB4E1;
	padding: 6px 0px 6px 10px;
	font-weight: 500;
	font-size: 14px;
	// border-radius: 5px;
	color: #fff;

}
.mybtn-blue > span
{
 	padding: 10px 0px 10px 8px;
}

.mybtn-blue .icon {
  	font-size: 11px;
  	background-color: #0C73AA;
 	padding: 14px 12px 12px 12px;
	// border-radius: 0px 5px 5px 0px;
	margin-left: 5px;
}

.mybtn-blue:hover
{
	color: #fff !important;
	text-decoration: none;
}


.mybtn-blue_l
{
	background-color: #5FB4E1;
	padding: 6px 0px 6px 10px;
	font-weight: 500;
	font-size: 14px;
	// border-radius: 5px;
	color: #fff;

}
.mybtn-blue_l > span
{
 	padding: 10px 0px 10px 8px;
}

.mybtn-blue_l .icon {
  	font-size: 11px;
  	background-color: #333;
 	padding: 14px 12px 12px 12px;
	// border-radius: 0px 5px 5px 0px;
	margin-left: 5px;
}

.mybtn-blue_l:hover
{
	color: #fff !important;
	text-decoration: none;
}


.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

/* Gallery */

.mobile-thumb
{
	width: 100%;
}


.portfolio-sidenav
{
	position: fixed;
	right:30px;
	z-index: 10000;
	top: 50%; 
}

.portfolio-item
{
	-webkit-box-shadow: 10px 10px 50px -21px rgba(0,0,0,0.75);
	-moz-box-shadow: 10px 10px 50px -21px rgba(0,0,0,0.75);
	box-shadow: 10px 10px 50px -21px rgba(0,0,0,0.75);
	// background-color: #333;

}

#portfolio h4
{
	color: #333;
	font-size: 12px;
	font-weight: 200;
	margin-top: 5px;
	text-transform: uppercase;
}

#port_prorail
{
	height: 55vh; 
	position: relative;
	
	#prorail_image-1 {
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		opacity: 0;
		img {
			height: 100%;
		}
	}

	#prorail_image-2 {
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		opacity: 0;
		img {
			height: 100%;
		}
	}


	#prorail_image-3 {
		height: 100%;
		position: absolute;
		left: 0px;
		top: 0px;
		opacity: 0;
		img {
			height: 100%;
		}
	}

	#prorail_title-1
	{
		position: absolute;
		left: 60px;	
		top: 68%;
		font-size:0.8em;
		z-index: 10;
		
		.text {
			width: 200px;
			position: relative;
			// opacity: 0;
		}

		span {
			width: 145px;
			opacity: 0;
		}

		.dot {
		  	position: absolute;
			left: 174px;
			top: 45px;
			color:#999;
			opacity: 0;
		}

	
	}

	#prorail_title-2
	{
		position: absolute;
		left: 60px;
		top: 50%;
		font-size:0.8em;
		z-index: 10;
		
		.text {
			width: 200px;
			position: relative;
			// opacity: 0;
		}

		span {
			width: 145px;
			opacity: 0;
		}

		.dot {
		  	position: absolute;
			left: 174px;
			top: 45px;
			color:#999;
			opacity: 0;
		}
	}

	#prorail_title-3
	{
		position: absolute;
		left: 60px;
		top: 35%;
		font-size:0.8em;
		z-index: 10;
		
		.text {
			width: 200px;
			position: relative;
			// opacity: 0;
		}

		span {
			width: 145px;
			opacity: 0;
		}

		.dot {
		  	position: absolute;
			left: 174px;
			top: 45px;
			color:#999;
			opacity: 0;
		}
	}
}

#prorail_webcanvas
{
	height:600px;
	width: 100vw;
	background: url('/include/images/portfolio/GuidoBackerra-prorail-webcanvas.jpg');
	background-repeat: no-repeat;
	position: relative;
}

.prorail_webcanvas::after {
    content: ""; // ::before and ::after both require content
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: white;
    opacity: .6;
}


#port_prorail
{
	height: 55vh; 
	position: relative; 
}


#port_ambu
{
	height: 55vh; 
	position: relative; 
	background-image: url('/include/images/portfolio/ambulantethuiszorg-thumb.jpg'); 
	background-size: cover; 
	background-repeat: no-repeat;
}

#port_apg
{
	height: 55vh; 
	position: 
	relative; 
	background-image: url('/include/images/portfolio/apg-thumb.jpg'); 
	background-size: cover; 
	background-repeat: 
	no-repeat;
}

#port_innova
{
	height: 55vh; 
	position: relative; 
	background-image: url('/include/images/portfolio/innovahousing-thumb.jpg'); 
	background-size: cover; 
	background-repeat: no-repeat;
}

#port_wml
{
	height: 55vh; 
	position: relative; 
	background-image: url('/include/images/portfolio/wml-thumb.jpg'); 
	background-size: cover; 
	background-repeat: no-repeat;
}

#port_neuhof
{
	height: 55vh; 
	position: relative; 
	background-image: url('/include/images/portfolio/neuhof-thumb.jpg'); 
	background-size: cover; 
	background-repeat: no-repeat;
}

@media screen and (max-width: 992px) {

	.portfolio-sidenav
	{
		position: fixed;
		right:15px;
		z-index: 10000;
		top: 50%; 
	}


	.portfolio-row
	{
		height: auto !important;
		margin-bottom: 60px;
	}

	#port_prorail,
	#port_ambu,
	#port_apg,
	#port_innova,
	#port_wml,
	#port_neuhof
	{
		height: auto !important;
		position: relative; 
		background-image: none; 
		padding-top: 0px !important;
		div
		{
			padding: 0px !important;
			div
			{
				padding: 0px !important;
				div
				{
					padding: 0px !important;
					padding-bottom:10% !important;
					text-align: left !important;
					h3,h4
					{
						padding-left: 20px;
					}
					a
					{
						margin-left: 20px;
					}
				}
			}
		}
	}
}
	


/* Style page content - use this if you want to push the page content to the right when you open the side navigation */

.breadcrumbs,
.breadcrumbs a
{
	color:#888;
}


.logo
{
	max-height: 26px;
	position: relative;
	left: -100px;
	z-index: 10000;
}

.logo_vervolg
{
	max-height: 26px;
	position: relative;
	left: 0px;
	z-index: 10000;
}

#main {
	transition: margin-left .5s;
	padding: 55px 0px 60px 0px;
	height: 100vh;
	background-image: url('/include/images/GBackerra-website-lg.jpg');
	background-size: cover;
	background-position: right;
	position: relative;
	z-index: 1000;
}

.h70 
{
	height: 70vh;
}

#portfolio
{
	position: relative;
	z-index: 100;
}

#netwerk
{
	position: relative;
	z-index: 1000;
	background-color: #fff;
}

#title
{
	border-bottom: 1px solid #f2f2f2;
}

#banner_homepage
{
	height: calc(100vh - 55px);
	padding-right: 7.5px;
}


#banner_homepage .content
{
	width: 100%;
	height: 100%;
	background-position: right bottom;
}


#banner_homepage .content .bannerlogo
{
	width: 40%;
	position: absolute;
	top: 15%;
	left: 15%;
}

#bannerlogo img 
{
	max-width: 200px;
}


#banner_homepage .content .title
{
	color: #000;
	position: absolute;
	top: 25%;
	left: 15%;
	font-size: 74px;
	font-weight: 200;
	line-height: 95px;
}



@media screen and (max-width: 767px) {
	
	#main {
		height: 100vh;
		background-image: url('/include/images/GBackerra-website-lg-mobile.jpg');
		background-repeat: no-repeat;
	}

	h3
	{
		font-size: 22px;
	}
	
	#banner_homepage .content .title
	{
		font-size: 34px;
		line-height: 48px;
	}

} 

#footer
{
	background-color: #111 !important;
	padding: 60px 0px;
	color: #888;
	font-size: 14px;
	line-height: 28px;
	text-align: left;
	font-weight: 200;
	position: relative;
	z-index: 1000;
}

#footer a
{
	color:#888;
}

#footer a:hover
,#footer h2
{
	color:#1E7FC9 ;
}

#footer .fab
{
	font-size: 1.8em;
}

@media screen  and (min-width: 767px) and (max-width: 1600px) {
	
	#banner_homepage .content .title
	{
		font-size: 48px;	
		line-height: 60px;	
	}

} 


@media screen and (max-height: 680px) {
  .project-desc 
  {
  	display: none !important;
  }
} 


/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} 

